type SourceSection = 'Profile' | 'Search' | 'Directory';

/**
 * Determines the source section based on the current window location path.
 *
 * @returns {SourceSection} The source section, which can be 'Profile', 'Search', 'Directory'.
 */
export const getSourceSection = (): SourceSection => {
    const path = window.location.pathname;
    if (path.includes('/professional/')) {
        return 'Profile';
    }
    if (path.includes('/search')) {
        return 'Search';
    }
    return 'Directory';
};
